@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,600;1,700&display=swap');

@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

.slick-list,.slick-slider,.slick-track{position:relative;display:block}.slick-loading .slick-slide,.slick-loading .slick-track{visibility:hidden}.slick-slider{box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list{overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}.slick-track{top:0;left:0}.slick-track:after,.slick-track:before{display:table;content:''}.slick-track:after{clear:both}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}.slick-arrow.slick-hidden{display:none}/*# sourceMappingURL=slick.min.css.map */

body {
  font-family: 'Montserrat', sans-serif;
}

header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 998;
}

.top_header {
  background: #ff7129;
  font-size: 14px;
  padding: 0 35px;
}

.top_header a {
  color: #fff;
  display: inline-block;
  padding: 5px 5px;
  margin: 0px 10px;
}

.main_header {
  background: rgba(47, 47, 43, 0.5);
  padding: 15px 35px;
}

.main_logo {
  display: inline-block;
  font-size: 24px;
  width: 170px;
  vertical-align: middle;
}

.main_menu {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 170px);
  text-align: right;
}

.main_menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main_menu ul li {
  display: inline-block;
  margin-left: 20px;
  position: relative;
}

.main_menu ul li a {
  display: block;
  padding: 10px 10px;
  color: #fff;
  line-height: 1;
  position: relative;
}

.main_logo a {
  color: #fff;
  font-weight: 600;
  display: block;
  font-style: italic;
}

.main_logo a span {
  color: #ff7129;
}

header a:hover {
  text-decoration: none;
}


.main_menu .btn-success:not(:disabled):not(.disabled).active,
.main_menu .btn-success:not(:disabled):not(.disabled):active,
.main_menu .show>.btn-success.dropdown-toggle,
.main_menu .dropdown-toggle:focus,
.main_menu .dropdown-toggle {
  background: transparent;
  border: 0;
  box-shadow: none;
}

.main_menu ul li .dropdown a {
  color: #000;
}

.main_menu ul li .dropdown-menu > a {
  padding: 0;
}

.main_menu ul li .dropdown-menu a:after {
  display: none;
}

.dropdown-menu {
  border-radius: 0;
  min-width: 250px;
  transform: none !important;
  right: 0 !important;
  top: 35px !important;
  left: auto !important;
}




.main_banner .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #2f2f2b;
  color: #fff;
  border: 0;
  padding: 10px;
  z-index: 99;
  text-align: center;
  line-height: 1;
  width: 40px;
  opacity: 0.4;
  font-size: 0;
  height: 40px;
}

.main_banner .slick-arrow:focus {
  outline: none;
}

.main_banner .slick-arrow:hover {
  opacity: 1;
}

.main_banner .slick-next {
  right: 35px;
}

.main_banner .slick-prev:after,
.main_banner .slick-next:after {
  content: '';
  width: 20px;
  height: 20px;
  position: absolute;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  left: 5px;
  top: 10px;
  transform: rotate(-45deg);
}

.main_banner .slick-prev:after {
  left: 14px;
  transform: rotate(135deg);
}

.main_banner .slick-prev {
  left: 35px;
}

.bg_title{
  background: #ff7129;
  border-radius: 12px !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
  position: relative;
  padding: 15px;
  color: #fff;
}

.sider_img img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.sider_img {
  position: relative;
}

.cm_banner:after,
.sider_img:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.3);
}

.main_banner .slick-dots {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex !important;
}

.main_banner .slick-dots li {
  margin: 10px;
}

.main_banner .slick-dots li button {
  background: #2f2f2b;
  border: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  color: #fff;
  opacity: 0.4;
  font-size: 0;
}

.main_banner .slick-dots li button:focus {
  outline: none;
}


.main_banner .slick-dots li button:hover,
.main_banner .slick-dots li.slick-active button{
  opacity: 1;
}

.card_img img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 12px 12px 0 0;
}

.cm_card {
  border-radius: 12px !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
  position: relative;
}

.card_body {
  padding: 15px;
}

.card_body h3 {
  color: #ff7129;
  font-size: 22px;
}

.card_body p {
  margin: 0;
  color: #2f2f2b;
}

footer {
  background: #2f2f2b;
  color: #fff;
  padding-top: 35px;
  margin-top: 50px;
}

.copyright {
  padding: 15px 35px;
  background: #000;
  font-size: 13px;
}

.copyright p {
  margin: 0;
}

footer a {
  color: #fff;
}

footer a:hover {
  text-decoration: none;
  color: #ff7129;
}

.footer_menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.footer_menu ul li a {
  padding: 10px 15px;
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
}

.footer_social a {
  display: inline-block;
  padding: 0 5px;
  margin: 0px 10px;
}

.cm_banner {
  min-height: 500px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 95px;
  position: relative;
}

.cm_banner h2 {
  color: #fff;
  font-size: 60px;
  font-weight: 600;
  text-shadow: 0px 4px 3px rgba(0,0,0,0.5);
}

.cm_banner p {
  color: #fff;
  font-size: 18px;
}

.cm_banner .container {
  position: relative;
  z-index: 99;
}


.contact_card {
  border-radius: 12px !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
  position: relative;
}

.contact_card form {
  background: #ff7129;
  padding: 30px;
  border-radius: 0 12px 12px 0;
  color: #fff;
}

.contact_item {
  position: relative;
  padding-left: 50px;
  margin: 35px 0;
}

.contact_item i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #ff7129;
}

.contact_item p {
  margin: 0;
}

.contact_item p a {
  color: #212529;
}

.contact_card form:before {
  content: '';
  width: 36px;
  height: 36px;
  background: #ff7129;
  position: absolute;
  left: -18px;
  top: 30px;
  transform: rotate(45deg);
}

.btn-primary {
  color: #fff;
  background-color: #2f2f2b;
  border-color: #2f2f2b;
}



.font-weight-bold {
  font-weight: 700!important;
  color: #ff7129;
}

.main_banner {
  position: relative;
}

.main_banner_text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  color: #fff;
}

.main_banner_text h2 {
  font-size: 56px;
  font-weight: 400;
  margin-bottom: 25px;
  margin-top: 90px;
  text-align: center;
}

.main_banner_text p {
  font-size: 24px;
  text-align: center;
  color: #dedede;
}

.pptc {
  border-top: 1px solid #4a4a4a;
  padding-top: 20px;
}

.pptc a {
  padding: 10px 15px;
  display: inline-block;
  margin-bottom: 15px;
  font-size: 14px;
}


.hotel_card {
  border-radius: 12px !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  transition: all 0.3s;
  transform: translateY(0px) scale(1);
}

.hotel_card:hover {
  transform: translateY(-5px) scale(1.01);
}

.main_banner_text h2 span {
  color: #ff7129;
}

.hotel_card h3 {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  width: 100%;
  margin: 0;
  background: rgba(0,0,0,0);
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 0%, #000000 100%);
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 0%,#000000 100%);
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 0%,#000000 100%);
  font-size: 20px;
  padding: 15px;
  padding-top: 40px;
}

.hotel_card img {
  width: 100%;
  height: 230px;
  object-fit: cover;
}

.cm_card.home_card h2 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  min-height: 42px;
}

.cm_card.home_card ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.home_card {
  min-height: 380px;
}

.cm_card.home_card ul li {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #383838;
  position: relative;
  padding-left: 30px;
}

.cm_card.home_card ul li:before {
  background-size: 20px;
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  background-image: url(./assets/img/tick.png);
}
.cm_counts svg {
  width: 70px;
  position: absolute;
  left: 35px;
  top: 23px;
}

.cm_counts h3 {
  font-size: 42px;
  font-weight: 700;
}
.img-width {width: 60px;
  position: absolute;
  left: 30px;
  top: 30px;}
.cm_counts {
  margin-bottom: 50px;
  background: #ff7129;
  border-radius: 12px !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
  position: relative;
  padding: 15px;
  padding-left: 130px;
  color: #fff;
}

.main_logo img {
  width: 140px;
  margin-left: 18px;
  margin-bottom: 10px;
}

.pp_wrap .cm_banner {
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: bottom;
}

@media(max-width: 1199px){
  body {
    font-size: 14px;
  }

  .main_menu ul li {
    margin-left: 2px;
  }

  .main_menu ul li a {
    font-size: 14px;
  }

  .top_header a {
    font-size: 13px;
  }
}


@media(min-width: 992px){
  .main_menu ul li:last-child a {
    background: #ff7129;
    font-weight: 600;
    border-radius: 30px;
    padding: 10px 15px;
  }

  .main_menu ul li a:after {
    content: '';
    position: absolute;
    width: 0px;
    left: 50%;
    height: 2px;
    bottom: 1px;
    background: #ff7129;
    border-radius: 5px;
    transform: translateX(-50%);
    transition: all 0.25s;
  }
  
  .main_menu ul li a.active:after,
  .main_menu ul li a:hover:after{
    width: 30px;
  }

  .cm_close_menu,
  .menu_icon {
    display: none !important;
  }
}

@media(max-width: 991px){
  .main_menu {
    position: fixed;
    width: 300px;
    background: #2f2f2b;
    top: 0;
    right: 0;
    height: 100%;
    overflow: auto;
    z-index: 9999;
    transition: all 0.3s;
    text-align: left;
    padding-top: 50px;
    transform: translateX(100%);
  }

  .main_menu ul li {
    margin-left: 0px;
    display: block;
    margin-bottom: 15px;
  }


  .main_menu ul li a {
    padding: 10px 20px;
    font-size: 18px !important;
  }

  .main_menu ul li a.active {
    background: #ff7129;
  }

  .menu_icon {
    position: absolute;
    right: 35px;
    top: 50px;
    color: #fff;
    font-size: 22px;
    cursor: pointer;
  }

  .menu_open .main_menu {
    transform: translateX(0);
  }


  .cm_close_menu {
    position: absolute;
    top: 10px;
    color: #fff;
    font-size: 22px;
    left: 20px;
    cursor: pointer;
}

.footer_menu ul {
  display: block;
  margin-bottom: 0;
  text-align: center;
}

.footer_menu ul li a {
  margin-bottom: 5px;
}

  
}

@media(max-width: 767px){
  .cm_card.home_card {
    margin-bottom: 30px;
  }

  .main_banner_text h2 {
    font-size: 40px;
  }

  .contact_card form:before {
    display: none !important;
  }

  .contact_card form {
    border-radius: 0 0px 12px 12px;
  }

  .main_banner .slick-arrow {
    display: none !important;
  }
}


@media(max-width: 576px){
  .top_header {
    display: block !important;
  }

  .top_header {
    padding: 0 15px;
  }

  .top_header a {
    margin: 0;
  }

  .menu_icon {
    right: 15px;
    top: 80px;
  }

  .main_header {
    padding: 15px;
  }

  .main_banner_text h2 {
    font-size: 30px;
  }

  .bg_title {
    font-size: 22px;
}

.cm_banner h2 {
  font-size: 40px;
}
}